<template>
  <div class="form-group">
    <label for="cpo-id" class="mr-2">Charge Point Operator</label>
    <h6 v-if="cpoError" class="font-weight-bold text-danger">{{ cpoError }}</h6>
    <select v-else name="cpo-id" id="cpo-id" class="form-control mr-2" v-model="cpoSelected" @change="cpSelected = ''">
      <option value="">Select Charge Point Operator</option>
      <option v-for="item in chargePointOperators" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label for="cp-id" class="mr-2">Charge Point </label>
    <h6 v-if="cpError" class="font-weight-bold text-danger">{{ cpError }}</h6>

    <select v-else name="cp-id" id="cp-id" class="form-control mr-2" v-model="cpSelected" @change="poolSelected = ''">
      <option value="">Select Charge Point</option>
      <option v-for="item in cpList" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label for="pool-id" class="mr-2">Charge Point Pool</label>
    <h6 v-if="cpError || poolError" class="font-weight-bold text-danger">{{ cpError ?? poolError }}</h6>
    <select v-else name="pool-id" id="pool-id" class="form-control mr-2" v-model="poolSelected">
      <option value="">Select Charge Point Pool</option>
      <option v-for="item in poolList" :value="item.id">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps(['chargePointOperators', 'cpoSelected', 'cpSelected', 'poolSelected']);
const cpoSelected = ref(props.cpoSelected !== '' ? +props.cpoSelected : '');
const cpSelected = ref(props.cpSelected !== '' ? +props.cpSelected : '');
const poolSelected = ref(props.poolSelected !== '' ? +props.poolSelected : '');

const cpList = computed(() => {
  return cpoSelected.value
    ? props.chargePointOperators.find((x) => x.id === cpoSelected.value)?.charge_points ?? []
    : [];
});

const poolList = computed(() => {
  return cpSelected.value ? cpList.value?.find((x) => x.id === cpSelected.value)?.pools ?? [] : [];
});

const cpoError = computed(() => {
  if (!Object.keys(props.chargePointOperators).length) {
    return 'No Charge Point Operators found';
  }

  return null;
});

const cpError = computed(() => {
  if (!cpoSelected.value) {
    return 'Please select a Charge Point Operator';
  }

  if (!Object.keys(cpList.value).length) {
    return 'No Charge Points found for the selected Charge Point Operator';
  }

  return null;
});

const poolError = computed(() => {
  if (!cpoSelected.value) {
    return 'Please select a Charge Point Operator';
  }

  if (!cpSelected.value) {
    return 'Please select a Charge Point';
  }

  if (!Object.keys(poolList.value).length) {
    return 'No Pools found for the selected Charge Point';
  }

  return null;
});
</script>
